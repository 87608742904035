/* eslint-disable complexity */
import { combineReducers } from 'redux';

export const MESSAGE = 'lobster-client/chat/MESSAGE';
export const CREATE_SELF_MESSAGE = 'lobster-client/chat/CREATE_SELF_MESSAGE';
export const ENABLE_TYPING_INDICATOR =
  'lobster-client/chat/ENABLE_TYPING_INDICATOR';
export const DISABLE_TYPING_INDICATOR =
  'lobster-client/chat/DISABLE_TYPING_INDICATOR';
export const OPEN_CHAT_PANEL = 'lobster-client/chat/OPEN_CHAT_PANEL';
export const CHECK_STATUS = 'lobster-client/chat/CHECK_STATUS';
export const CLOSE_CHAT_PANEL = 'lobster-client/chat/CLOSE_CHAT_PANEL';
export const SET_LAUNCHER_COMPLETED_FIRST_PROMPT =
  'lobster-client/chat/SET_LAUNCHER_COMPLETED_FIRST_PROMPT';
export const CHAT_PANEL_ANIMATE = 'lobster-client/chat/CHAT_PANEL_ANIMATE';
export const USER_STARTED_TYPING = 'lobster-client/chat/USER_STARTED_TYPING';
export const USER_STOPPED_TYPING = 'lobster-client/chat/USER_STOPPED_TYPING';
export const AGENT_JOINED_CHAT = 'lobster-client/chat/AGENT_JOINED_CHAT';
export const AGENT_DETAILS_UPDATED =
  'lobster-client/chat/AGENT_DETAILS_UPDATED';
export const AGENT_LEFT_CHAT = 'lobster-client/chat/AGENT_LEFT_CHAT';
export const UPLOAD_FILE = 'lobster-client/chat/UPLOAD_FILE';
export const UPLOAD_FILE_REQUEST = 'lobster-client/chat/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'lobster-client/chat/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'lobster-client/chat/UPLOAD_FILE_ERROR';
export const COMPLETE_CHAT = 'lobster-client/chat/COMPLETE_CHAT';
export const USER_ENGAGED = 'lobster-client/chat/USER_ENGAGED';
export const RESET_CHAT = 'lobster-client/chat/RESET_CHAT';
export const HANDOVER_COMPLETE = 'lobster-client/chat/HANDOVER_COMPLETE';
export const HANDOVER_FAILED = 'lobster-client/chat/HANDOVER_FAILED';

const destructureReverse = array => [array.slice(0, -1), array.slice(-1)[0]];

export default combineReducers({
  messages: messagesReducer,
  typingIndicator: typingIndicatorReducer,
  agents: agentsReducer,
  panelOpen: panelOpenReducer,
  canAnimate: panelAnimateReducer,
  launcher: launcherReducer,
  userEngaged: userEngagedReducer
});

// eslint-disable-next-line default-param-last
function messagesReducer(state = [], action) {
  let tail, lastGroup, newState, lastInvitation;

  switch (action.type) {
    case MESSAGE:
    case UPLOAD_FILE_REQUEST:
      [tail, lastGroup] = destructureReverse(state);

      if (lastGroup && lastGroup.owner === action.message.owner) {
        return [
          ...tail,
          {
            ...lastGroup,
            messages: [...lastGroup.messages, action.message]
          }
        ];
      }

      return [
        ...state,
        {
          owner: action.message.owner,
          messages: [action.message]
        }
      ];
    case UPLOAD_FILE_SUCCESS:
      newState = [
        ...state.filter(item => {
          const filteredMessages = item.messages.filter(
            message => message.value !== action.message.requestId
          );
          if (filteredMessages.length) {
            item.messages = [...filteredMessages];
            return item;
          } else {
            return item.messages.filter(
              message => message.value !== action.message.requestId
            ).length;
          }
        })
      ];

      if (newState) {
        [tail, lastGroup] = destructureReverse(newState);
      }

      if (lastGroup && lastGroup.owner === 'self') {
        return [
          ...tail,
          {
            ...lastGroup,
            messages: [
              ...lastGroup.messages,
              {
                owner: 'self',
                date: new Date().toISOString(),
                type: 'file',
                value: {
                  name: action.message.name,
                  url: action.message.url
                }
              }
            ]
          }
        ];
      }

      return [
        ...newState,
        {
          owner: 'self',
          messages: [
            {
              owner: 'self',
              date: new Date().toISOString(),
              type: 'file',
              value: {
                name: action.message.name,
                url: action.message.url
              }
            }
          ]
        }
      ];
    case UPLOAD_FILE_ERROR:
      newState = [...state];
      newState.forEach(chat => {
        chat.messages.forEach(message => {
          if (
            message.type === 'file-request' &&
            message.value.requestId === action.message.requestId
          ) {
            message.value.error = 'File failed to upload';
          }
        });
      });

      return newState;
    case RESET_CHAT: {
      return [];
    }

    case COMPLETE_CHAT:
      return [...state];

    case HANDOVER_COMPLETE:
    case HANDOVER_FAILED:
      newState = [...state];
      lastInvitation = newState
        .toReversed()
        .find(({ messages }) =>
          messages
            .toReversed()
            .find(
              message =>
                message.owner === 'system' && message.type === 'invitation'
            )
        );

      if (lastInvitation) {
        lastInvitation.messages.forEach(message => {
          if (message.owner === 'system' && message.type === 'invitation') {
            message.invitationProcessed = true;
          }
        });
      }
      return state;

    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
function typingIndicatorReducer(state = [], action) {
  switch (action.type) {
    case ENABLE_TYPING_INDICATOR:
      return [...state, action.user];

    case DISABLE_TYPING_INDICATOR:
      return state.filter(user => user !== action.user);

    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function panelOpenReducer(state = false, action) {
  switch (action.type) {
    case OPEN_CHAT_PANEL:
      return true;
    case CLOSE_CHAT_PANEL:
      return false;

    default:
      return state;
  }
}

export function launcherReducer(
  // eslint-disable-next-line default-param-last
  state = { firstPromptCompleted: false },
  action
) {
  switch (action.type) {
    case SET_LAUNCHER_COMPLETED_FIRST_PROMPT:
      return {
        ...state,
        firstPromptCompleted: action.state
      };
    default:
      return state;
  }
}

export function userEngagedReducer(state = false, action = {}) {
  switch (action.type) {
    case USER_ENGAGED:
      return action.engaged;
    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
export function panelAnimateReducer(state = true, action) {
  switch (action.type) {
    case CHAT_PANEL_ANIMATE:
      return action.animate;

    default:
      return state;
  }
}

// eslint-disable-next-line default-param-last
function agentsReducer(state = [], action) {
  switch (action.type) {
    case AGENT_JOINED_CHAT:
      return [...state, action.agent];

    case AGENT_LEFT_CHAT:
      return state.filter(agent => agent !== action.agent);

    case AGENT_DETAILS_UPDATED:
      return state.map(agent =>
        agent._id === action.agent._id ? { ...agent, ...action.agent } : agent
      );

    default:
      return state;
  }
}

export function createMessage(message) {
  return {
    type: MESSAGE,
    message: {
      date: new Date().toISOString(),
      ...message
    }
  };
}

export function createSelfMessage(message, title) {
  return {
    type: MESSAGE,
    message: {
      owner: 'self',
      type: 'text',
      value: message,
      title,
      date: new Date().toISOString()
    }
  };
}

export function fileUploadRequest(message) {
  return {
    type: UPLOAD_FILE_REQUEST,
    message: {
      date: new Date().toISOString(),
      ...message
    }
  };
}

export function fileUploadSuccess(message) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    message: {
      date: new Date().toISOString(),
      ...message
    }
  };
}

export function fileUploadError(message) {
  return {
    type: UPLOAD_FILE_ERROR,
    message: {
      date: new Date().toISOString(),
      ...message
    }
  };
}

export function fileUpload(data) {
  return {
    type: UPLOAD_FILE,
    data
  };
}

export function handoverComplete(payload) {
  return {
    type: HANDOVER_COMPLETE,
    payload
  };
}

export function handoverFailed(payload) {
  return {
    type: HANDOVER_FAILED,
    payload
  };
}

export function completeChat() {
  return {
    type: COMPLETE_CHAT
  };
}

export function enableTypingIndicator(payload) {
  return {
    type: ENABLE_TYPING_INDICATOR,
    user: payload.user
  };
}

export function disableTypingIndicator(payload) {
  return {
    type: DISABLE_TYPING_INDICATOR,
    user: payload.user
  };
}

export function agentJoinedChat(payload) {
  return {
    type: AGENT_JOINED_CHAT,
    agent: payload
  };
}

export function agentDetailsUpdated(payload) {
  return {
    type: AGENT_DETAILS_UPDATED,
    agent: payload
  };
}

export function agentLeftChat(payload) {
  return {
    type: AGENT_LEFT_CHAT,
    agent: payload
  };
}

export function userStartedTyping() {
  return {
    type: USER_STARTED_TYPING
  };
}

export function userStoppedTyping() {
  return {
    type: USER_STOPPED_TYPING
  };
}

export function openChatPanel(isNew) {
  return {
    type: OPEN_CHAT_PANEL,
    isNew
  };
}

export function closeChatPanel() {
  return {
    type: CLOSE_CHAT_PANEL
  };
}

export function checkStatus() {
  return {
    type: CHECK_STATUS
  };
}

export function setCanAnimate(animate) {
  return {
    type: CHAT_PANEL_ANIMATE,
    animate
  };
}

export function setLauncherCompletedFirstPrompted(state) {
  return {
    type: SET_LAUNCHER_COMPLETED_FIRST_PROMPT,
    state
  };
}

export function setUserEngaged(value) {
  return {
    type: USER_ENGAGED,
    engaged: value
  };
}

export function resetChat() {
  return {
    type: RESET_CHAT
  };
}
