import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FloatingActionButton from './launchers/FloatingActionButton';
import Banner from './launchers/Banner';
import Window from './launchers/Window';
import { setLauncherCompletedFirstPrompted } from '../chat/reducer';
import { DEFAULT_PRIMARY_COLOR } from '../chat/utils/constants';
export default function Container({
  setIsOpen,
  isOpen,
  setIsPrompting,
  isPrompting,
  firstPromptCompleted,
  isEmbedded,
  embedTarget
}) {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.config.theme);
  const configLauncherIconURL = useSelector(
    state => state.config.launcherIconURL
  );
  const additionalLauncherIcons = useSelector(
    state => state.config.additionalLauncherIcons
  );
  const cssOverrides = theme.cssOverrides?.Launcher;
  const containerCssOverrides = theme.cssOverrides?.LauncherContainer;
  const promptCssOverrides = theme.cssOverrides?.Prompt;
  const headerColour =
    theme.topBarColour?.background ||
    theme.primaryColour ||
    DEFAULT_PRIMARY_COLOR;
  const headerTextColour = theme.topBarColour?.text || 'white';
  const promptColour = theme.promptColour?.background;
  const promptTextColour = theme.promptColour?.text;
  const position = theme.position || 'right';
  const bottomMargin = theme.bottomMargin;
  const sideMargin = theme.sideMargin;
  const appName = useSelector(state => state.config.appName);
  const prompt = theme.prompt;

  const launcherType = theme.chatWidgetStyling
    ? theme.chatWidgetStyling?.includes('window_')
      ? 'window'
      : 'floatingActionButton'
    : theme.launcherType;

  const animateLauncher = theme?.animateLauncher;

  useEffect(() => {
    if (prompt && isPrompting) {
      const timeout = setTimeout(() => {
        setIsPrompting(false);
      }, prompt.duration);

      return () => clearTimeout(timeout);
    }
  }, [dispatch, setIsPrompting, isPrompting, prompt]);

  useEffect(() => {
    if (prompt && !firstPromptCompleted) {
      setIsPrompting(true);
      dispatch(setLauncherCompletedFirstPrompted(true));
    }
  }, [dispatch, setIsPrompting, firstPromptCompleted, prompt]);

  const launcherIconURL =
    isOpen && additionalLauncherIcons?.chatWindowOpen
      ? additionalLauncherIcons.chatWindowOpen
      : configLauncherIconURL;

  switch (launcherType) {
    case 'floatingActionButton': {
      return (
        <FloatingActionButton
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          prompt={prompt}
          setIsPrompting={setIsPrompting}
          isPrompting={isPrompting}
          promptCssOverrides={promptCssOverrides}
          headerColour={headerColour}
          headerTextColour={headerTextColour}
          cssOverrides={cssOverrides}
          launcherIconURL={launcherIconURL}
          position={position}
          isEmbedded={isEmbedded}
          embedTarget={embedTarget}
          animateLauncher={animateLauncher}
          bottomMargin={bottomMargin}
          sideMargin={sideMargin}
          widgetType={theme.chatWidgetStyling}
          avatarName={appName}
          promptColour={promptColour}
          promptTextColour={promptTextColour}
        />
      );
    }
    case 'window':
      return (
        <Window
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          headerColour={headerColour}
          headerTextColour={headerTextColour}
          cssOverrides={cssOverrides}
          position={position}
          avatarName={appName}
          launcherIconURL={launcherIconURL}
          widgetType={theme.chatWidgetStyling}
          bottomMargin={bottomMargin}
          sideMargin={sideMargin}
          isPrompting={isPrompting}
          promptCssOverrides={promptCssOverrides}
          prompt={prompt}
          promptColour={promptColour}
          promptTextColour={promptTextColour}
          containerCssOverrides={containerCssOverrides}
        />
      );
    case 'banner':
    default:
      return (
        <Banner
          setIsOpen={setIsOpen}
          headerColour={headerColour}
          headerTextColour={headerTextColour}
          cssOverrides={cssOverrides}
          position={position}
        />
      );
  }
}

Container.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsPrompting: PropTypes.func.isRequired,
  isPrompting: PropTypes.bool.isRequired,
  firstPromptCompleted: PropTypes.bool,
  isEmbedded: PropTypes.bool.isRequired,
  embedTarget: PropTypes.string,
  avatar: PropTypes.string
};
