/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../localisation/TranslationContext';
import cssOverrides from '../../utils/cssOverrides';
import OtherMessage from './OtherMessage';
import { ReactComponent as Download } from '../../assets/download.svg';
import { ReactComponent as ConnectingIcon } from '../../assets/connecting-static.svg';
import {
  TRANSCRIPT_EXPIRY_HIDE_TIME,
  DEFAULT_TEXT_COLOR,
  DEFAULT_PRIMARY_COLOR
} from '../../utils/constants';
import PropTypes from 'prop-types';
import { useLottie } from 'lottie-react';
import animationData from '../../assets/connecting-animation.json';

const messagePropType = PropTypes.shape({
  owner: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  invitationProcessed: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  replies: PropTypes.arrayOf(PropTypes.string.isRequired),
  top_element_style: PropTypes.string,
  date: PropTypes.string
});

export default function SystemMessage({
  message,
  isFirst,
  isLast,
  showReplies,
  animate
}) {
  const { t } = useTranslation();
  const theme = useSelector(state => state.config.theme);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const transcriptURL = message.value?.transcriptURL;
  const hasUserMessage = message.value?.hasUserMessage;
  const overrides = theme.cssOverrides?.SystemMessage || {};
  const { rootAgent } = useSelector(state => state.config);

  const timeCompleted =
    message.type === 'complete-chat' && new Date(message.date).getTime();
  const timeoutRef = useRef();

  const [hideTranscriptDownload, setHideTranscriptDownload] = useState(
    timeCompleted &&
      TRANSCRIPT_EXPIRY_HIDE_TIME - (Date.now() - timeCompleted) <= 0
  );

  if (timeCompleted && !hideTranscriptDownload) {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(
        () => setHideTranscriptDownload(true),
        TRANSCRIPT_EXPIRY_HIDE_TIME - (Date.now() - timeCompleted)
      );
    }
  }

  useEffect(
    () => () => timeoutRef.current && clearTimeout(timeoutRef.current),
    []
  );

  if (message.type === 'joined-chat') {
    const rootAgentId = rootAgent[rootAgent.type];
    const isRootAgent = message.value._id === rootAgentId;

    if (!isRootAgent) {
      return (
        <WithBorder theme={theme} css={overrides}>
          <div>
            <span>
              {message.value.displayName} {t('agent_joined_chat')}
            </span>
          </div>
        </WithBorder>
      );
    }

    return null;
  } else if (message.type === 'invitation') {
    if (message.value.type !== 'user') {
      return (
        <InvitationMessage message={message} theme={theme} css={overrides} />
      );
    }
    return null;
  } else if (message.type === 'left-chat') {
    return null;
  } else if (message.type === 'complete-chat') {
    return (
      <>
        <WithBorder theme={theme} css={overrides}>
          <div>
            <span>
              {message.value.reason === 'User ended chat'
                ? t('user_ended_chat')
                : t('chat_complete')}
            </span>
          </div>
        </WithBorder>
        {transcriptURL && !hideTranscriptDownload && hasUserMessage && (
          <DownloadTranscriptButton
            className="download-transcript-button"
            href={transcriptURL}
            target="_blank"
            rel="noreferrer"
          >
            {t('download_transcript')} <DownloadIcon />
          </DownloadTranscriptButton>
        )}
      </>
    );
  } else if (message.type === 'generic') {
    return (
      <OtherMessage
        type={message.type}
        value={message.value}
        owner={message.owner}
        isFirst={isFirst}
        isLast={isLast}
        replies={showReplies ? message.replies : undefined}
        top_element_style={message.top_element_style}
        animate={animate}
      />
    );
  } else {
    return (
      <SystemMessageWrapper
        animate={animate}
        theme={theme}
        css={overrides}
        fullScreen={fullScreen}
      >
        <SystemBubble>{message.value}</SystemBubble>
      </SystemMessageWrapper>
    );
  }
}

function InvitationMessage({ message, theme, overrides }) {
  const { View: InvitationAnimation } = useLottie({
    animationData: animationData,
    loop: true,
    autoplay: true,
    style: {
      width: 36,
      height: 36,
      flexShrink: 0
    }
  });

  if (message.value.type === 'user') {
    return null;
  }

  return (
    <WithBorder theme={theme} css={overrides}>
      <span>
        {message.invitationProcessed ? (
          <ConnectingIcon />
        ) : (
          <span>{InvitationAnimation}</span>
        )}
      </span>
    </WithBorder>
  );
}

InvitationMessage.propTypes = {
  message: messagePropType,
  theme: PropTypes.object,
  css: PropTypes.object,
  overrides: PropTypes.object
};

SystemMessage.propTypes = {
  message: messagePropType,
  showReplies: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  animate: PropTypes.bool
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const DownloadTranscriptButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  height: 40px;
  border: 1px solid
    ${props => props.theme.primaryColour || DEFAULT_PRIMARY_COLOR};
  font-size: 16px;
  background-color: transparent;
  border-radius: 10px;
  color: currentColor !important;
  margin: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease-in-out;
  padding: 0 16px;

  svg {
    color: ${props => props.theme.primaryColour || DEFAULT_PRIMARY_COLOR};
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${props =>
      props.theme.primaryColour || DEFAULT_PRIMARY_COLOR}15;
    transform: translateY(-1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px
      ${props => props.theme.primaryColour || DEFAULT_PRIMARY_COLOR}40;
  }

  &:active {
    transform: translateY(1px);
    background-color: ${props =>
      props.theme.primaryColour || DEFAULT_PRIMARY_COLOR}25;
  }

  &:focus-visible {
    outline: 2px solid
      ${props => props.theme.primaryColour || DEFAULT_PRIMARY_COLOR};
    outline-offset: 2px;
  }

  ${cssOverrides}
`;

const WithBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 7.5px 24px;
  color: ${props => props.theme.systemMessageColour || DEFAULT_TEXT_COLOR};
  font-size: 12px;
  font-style: italic;

  > div {
    text-align: center;
    opacity: 0.5;
    max-width: 70%;
    word-wrap: break-word;
    word-break: break-word;
  }

  &:before,
  &:after {
    flex: 1;
    content: '';
    background: ${props =>
      props.theme.systemMessageColour || DEFAULT_TEXT_COLOR};
    height: 0.5px;
    opacity: 0.5;
  }
  ${cssOverrides}
`;

const SystemMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.primaryColour || '#eeeeee'};
  line-height: 1.3em;
  margin: 2px;
  max-width: 75%;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          @media screen and (min-width: 950px) {
            max-width: 65%;
          }
        `
      : null};
  width: fit-content;
  padding: 10px 14px;
  border-radius: 10px 6px 6px 10px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 100% 100%;
  transition: border-radius 300ms;
  ${cssOverrides}

  &:first-of-type {
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
  }
`;

const SystemBubble = styled.div`
  opacity: 0.8;
  font-size: 0.9em;
  word-break: break-word;
`;

const DownloadIcon = styled(Download)`
  width: 16px;
  height: 16px;
`;
