import io from 'socket.io-client';
import {
  USER_STOPPED_TYPING,
  USER_STARTED_TYPING,
  MESSAGE,
  OPEN_CHAT_PANEL,
  CHECK_STATUS,
  UPLOAD_FILE,
  createMessage,
  enableTypingIndicator,
  disableTypingIndicator,
  agentJoinedChat,
  agentLeftChat,
  fileUploadRequest,
  fileUpload,
  fileUploadSuccess,
  fileUploadError,
  handoverComplete,
  handoverFailed,
  COMPLETE_CHAT,
  RESET_CHAT
} from '../reducer';
import SocketHelper from './socketHelper';

let helper;

export default ({
    api,
    client,
    pipeline,
    debug,
    metadata,
    authentication,
    appName
  }) =>
  store => {
    if (
      !helper?.setDispatch ||
      (helper?.client !== client && helper?.pipeline !== pipeline)
    ) {
      helper = new SocketHelper(
        io,
        api,
        client,
        pipeline,
        store.dispatch,
        createMessage,
        enableTypingIndicator,
        disableTypingIndicator,
        agentJoinedChat,
        agentLeftChat,
        fileUploadRequest,
        fileUpload,
        fileUploadSuccess,
        fileUploadError,
        handoverComplete,
        handoverFailed,
        debug,
        metadata,
        authentication,
        appName
      );
    } else {
      helper.setDispatch(store.dispatch);
    }

    return next => action => {
      switch (action.type) {
        case OPEN_CHAT_PANEL:
        case CHECK_STATUS:
          helper.checkStatus();
          break;

        case MESSAGE:
          if (action.message.owner === 'self') {
            helper.emitMessage(action.message);
          }
          break;

        case UPLOAD_FILE:
          helper.emitFile(action.data);
          break;

        case USER_STARTED_TYPING:
          helper.emitStartedTyping();
          break;

        case USER_STOPPED_TYPING:
          helper.emitStoppedTyping();
          break;

        case COMPLETE_CHAT:
          helper.emitCompleteChat();
          break;

        case RESET_CHAT:
          helper.resetChat();
          break;

        default:
          break;
      }

      return next(action);
    };
  };
